<template>
    <b-row class="justify-content-center">
        <b-col md="8">
            <b-card>
                <b-form v-on:submit.prevent>
                    <b-row>
                        <b-col cols="12" class="text-left mb-2">
                            <b-badge :variant="statusVariant(this.status)">
                                <span v-if="this.status === 1">Waiting Approval</span>
                                <span v-else-if="this.status === 2">Approved</span>
                                <span v-else-if="this.status === 3">Rejected</span>
                                <span v-else-if="this.status === 4">Waiting Approval For Return</span>
                                <span v-else-if="this.status === 5">Return Approved</span>
                                <span v-else-if="this.status === 6">Return Rejected</span>
                            </b-badge>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group
                            label="Name"
                            label-for="cash-advance"
                            >
                                <b-form-input
                                    id="cash-advance"
                                    placeholder="Name"
                                    v-model="form.name"
                                    disabled
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group
                            label="Amount"
                            label-for="amount"
                            >
                                <cleave
                                    id="amount"
                                    placeholder="Amount"
                                    v-model="form.amount"
                                    class="form-control"
                                    :raw="false"
                                    :options="options.number"
                                    :disabled="this.status !== 1 ? true : false"
                                    required
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                            label="Description"
                            label-for="description"
                            >
                                <b-form-textarea
                                    id="description"
                                    placeholder="Description"
                                    v-model="form.description"
                                    :disabled="this.status !== 1 ? true : false"
                                    required
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="this.status === 4 | this.status === 5">
                            <b-form-group
                            label="Return Amount"
                            label-for="return_amount"
                            >
                                <cleave
                                    id="return_amount"
                                    placeholder="Return Amount"
                                    v-model="form.return_amount"
                                    class="form-control"
                                    :raw="false"
                                    :options="options.number"
                                    disabled
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="this.status === 4 | this.status === 5">
                            <b-form-group
                            label="Return Description"
                            label-for="return_description"
                            >
                                <b-form-textarea
                                    id="return_description"
                                    placeholder="Return Description"
                                    v-model="form.return_description"
                                    disabled
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="this.status === 3">
                            <b-form-group
                            label="Reject Reason"
                            label-for="reject_reason"
                            >
                            <div>
                                <b-form-textarea
                                    id="reject_reason"
                                    placeholder="Reject Reason"
                                    v-model="form.reject_reason"
                                    disabled
                                />
                            </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="this.status === 6">
                            <b-form-group
                            label="Return Reject Reason"
                            label-for="return_reject_reason"
                            >
                            <div>
                                <b-form-textarea
                                    id="return_reject_reason"
                                    placeholder="Return Reject Reason"
                                    v-model="form.return_reject_reason"
                                    disabled
                                />
                            </div>
                            </b-form-group>
                        </b-col>

                        <!-- submit and reset -->
                        <b-col md="12" class="mt-2" v-if="status === 1">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            class="mr-1"
                            @click="updateCashAdvance()"
                            block
                            >
                            Update
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
        <b-col cols="12" md="12" class="mt-0" v-if="status === 1">
            <b-row>
                <b-col>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="danger"
                    class="mr-1"
                    block
                    v-b-modal.modal-reject
                    >
                    Reject
                    </b-button>
                </b-col>
                <b-col>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="success"
                    class="mr-1"
                    block
                    @click="approve()"
                    >
                    Approve
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" md="12" class="mt-0" v-if="status === 4">
            <b-row>
                <b-col>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="danger"
                    class="mr-1"
                    block
                    v-b-modal.modal-return-reject
                    >
                    Return Reject
                    </b-button>
                </b-col>
                <b-col>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="success"
                    class="mr-1"
                    block
                    @click="returnApprove()"
                    >
                    Return Approve
                    </b-button>
                </b-col>
            </b-row>
        </b-col>

        <!-- modal reject -->
        <b-modal
        id="modal-reject"
        title="Reject Form"
        ok-title="Reject"
        cancel-variant="outline-secondary"
        hide-footer
        >
            <validation-observer ref="loginValidation">
            <b-form ref="form" @submit.prevent>
                <b-form-group
                :state="errors"
                label="Reject Reason"
                label-for="reject"
                invalid-feedback="Reject Reason required"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="reject"
                    rules="required"
                    >
                        <b-form-input
                            id="reject"
                            name="reject"
                            type="text"
                            v-model="form.reject_reason"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Reject Reason"
                            required="required"
                        />
                        <small class="text-danger">{{ errors[0] || errMessage }}</small>
                    </validation-provider>
                </b-form-group>

                <b-row>
                    <b-col>
                        <b-button
                            class="mt-3"
                            type="submit"
                            variant="danger"
                            block
                            @click="reject()"
                        >
                            Reject
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            </validation-observer>
        </b-modal>
        <!-- end modal reject -->
        <!-- modal reject -->
        <b-modal
        id="modal-return-reject"
        title="Return Reject Form"
        ok-title="Return Reject"
        cancel-variant="outline-secondary"
        hide-footer
        >
            <validation-observer ref="loginValidation">
            <b-form ref="form" @submit.prevent>
                <b-form-group
                :state="errors"
                label="Return Reject Reason"
                label-for="return_reject"
                invalid-feedback="Return Reject Reason required"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="return_reject"
                    rules="required"
                    >
                        <b-form-input
                            id="return_reject"
                            name="return_reject"
                            type="text"
                            v-model="form.return_reject_reason"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Return Reject Reason"
                            required="required"
                        />
                        <small class="text-danger">{{ errors[0] || errMessage }}</small>
                    </validation-provider>
                </b-form-group>

                <b-row>
                    <b-col>
                        <b-button
                            class="mt-3"
                            type="submit"
                            variant="danger"
                            block
                            @click="returnReject()"
                        >
                            Reject
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            </validation-observer>
        </b-modal>
        <!-- end modal reject -->
    </b-row>
</template>

<script>
import {
    BFormTextarea, BBadge, BImg, BFormTimepicker, BFormDatepicker, BFormSelect, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    LMap, LTileLayer, LMarker, LPopup
} from 'vue2-leaflet'
import flatPickr from 'vue-flatpickr-component'
import { $themeConfig } from "@themeConfig"
import Cleave from 'vue-cleave-component'

export default {
    components: {
        ToastificationContent,
        flatPickr,
        Cleave,
        BFormTextarea,
        BBadge,
        ValidationProvider,
        ValidationObserver,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        BImg,
        BFormTimepicker,
        BFormDatepicker,
        BFormSelect,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                name: '',
                amount: null,
                description: '',
                reject_reason: '',
                return_description: '',
                return_reject_reason: '',
                return_amount: null
            },
            status: '',
            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                },
            },
            errors: '',
            errMessage: ''
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1 : 'light-warning',
                2 : 'light-success',
                3 : 'light-danger',
                4 : 'light-warning',
                5 : 'light-success',
                6 : 'light-danger'
            }

            return status => statusColor[status]
        },
    },
    setup() {
        // App Name
        const { baseUrl, apiUrl } = $themeConfig.app;
        return {
            baseUrl,
            apiUrl
        };
    },
    created() {
        this.getDetails()
    },
    methods: {
        getDetails() {
            const id = this.$route.params.id
            this.$http
            .get('cash-advance/' +id)
            .then((response) => {
                // console.log(response.data.data)
                this.status = response.data.data.status
                this.form.name = response.data.data.user_name
                this.form.amount = response.data.data.amount
                this.form.description = response.data.data.description
                this.form.reject_reason = response.data.data.reject_reason
                this.form.return_description = response.data.data.return_description
                this.form.return_reject_reason = response.data.data.return_reject_reason
                this.form.return_amount = response.data.data.return_amount
            }).catch((errors) => {
                console.log(errors.response)
            });
        },
        updateCashAdvance() {
            const id = this.$route.params.id
            var newAmount = this.form.amount
            newAmount = newAmount.replace(/\,/g,'')
            this.$http
            .post('cash-advance/'+id+'/update', {
                'amount': newAmount,
                'description': this.form.description,
            })
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success update Cash Advance',
                        variant: 'success',
                    },
                })
                location.href = "/cash-advance"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        approve() {
            const id = this.$route.params.id
            this.$http
            .post('cash-advance/'+id+'/approve')
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Cash Advance Approved',
                        variant: 'success',
                    },
                })
                location.href = "/cash-advance"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        reject() {
            const id = this.$route.params.id
            this.$http
            .post('cash-advance/'+id+'/reject', {
                'reject_reason': this.form.reject_reason
            })
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Cash Advance Rejected',
                        variant: 'danger',
                    },
                })
                location.href = "/cash-advance"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        returnApprove() {
            const id = this.$route.params.id
            this.$http
            .post('cash-advance/'+id+'/return-approve')
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Return Cash Advance Approved',
                        variant: 'success',
                    },
                })
                location.href = "/cash-advance"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        returnReject() {
            const id = this.$route.params.id
            this.$http
            .post('cash-advance/'+id+'/return-reject', {
                'return_reject_reason': this.form.return_reject_reason
            })
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Return Cash Advance Rejected',
                        variant: 'danger',
                    },
                })
                location.href = "/cash-advance"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>